<template>
    <v-container fluid class="down-top-padding">
        <v-breadcrumbs large :items="breadcrumbs" class="pl-0 pt-0">
      <template v-slot:divider>
        <v-icon>mdi-forward</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row>
      <v-col cols="4">
      </v-col>
    </v-row>
    </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>